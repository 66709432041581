<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Asignaciones Internos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">Asignaciones</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button 
                        type="button" 
                        class="btn btn-danger"
                        @click="resetFilter"
                      >
                        <i class="fas fa-broom"></i>
                      </button>
                      <button
                        type="button"
                        class="btn bg-primary"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row justify-content-center">
                  <div class="form-group col-md-1">
                    <label># Solicitud</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      v-model="filtros.id"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Nombre Usuario</label>
                    <input
                      type="text"
                      class="form-control form-control-sm p-0"
                      v-model="filtros.user"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Tipo Solicitud</label>
                    <select
                      v-model="filtros.tipo_solicitud"
                      class="form-control form-control-sm"
                      placeholder="Tipo Solicitud"
                      label="descripcion"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_solicitud in listasForms.tipos_solicitudes"
                        :key="tipo_solicitud.numeracion"
                        :value="tipo_solicitud.numeracion"
                      >
                        {{ tipo_solicitud.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha"
                      @input="getIndex()"
                    />
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm text-nowrap mb-0"
                >
                  <thead class="bg-dark">
                    <tr class="text-center">
                      <th># Solicitud</th>
                      <th>Nombre Usuario</th>
                      <th>Tipo Solicitud</th>
                      <th>Clase Solicitud</th>
                      <th>Fecha</th>
                      <th>Estado</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="solicitud in solicitudesInterna.data"
                      :key="solicitud.id"
                    >
                      <td class="text-center">
                        <button 
                          type="button" 
                          data-toggle="modal" 
                          data-target="#modal-form-detalle-CsIntSolicitud" 
                          class="btn btn-sm btn-default py-0"
                          v-if="
                            $store.getters.can(
                              'cs.asignacionesInterno.index'
                            )
                          "
                          @click="llenarModal(solicitud)"
                        > 
                          {{ solicitud.id }}
                        </button>
                      </td>
                      <td>{{ solicitud.user.name }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            solicitud.tipo_solicitud == 1
                              ? 'bg-lightblue'
                              : 'bg-danger'
                          "
                          >{{ solicitud.tipoSolicitud }}</span
                        >
                        <div
                          class="row justify-content-center"
                          v-if="solicitud.tipo_emergencia == 2"
                        >
                          <span class="badge bg-pink">Extemporaneo</span>
                        </div>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            solicitud.clase_solicitud == 1
                              ? 'bg-navy'
                              : 'bg-lime'
                          "
                          >{{ solicitud.claseSolicitud }}</span
                        >
                      </td>
                      <td class="text-center">{{ solicitud.fecha }}</td>
                      <td class="text-center">
                        <p
                          v-for="(detSoli,
                          index) in solicitud.det_solicitudes"
                          :key="detSoli.id"
                          class="p-0 m-0"
                        >
                          <span
                            class="badge"
                            :class="
                              detSoli.estado == 1
                                ? 'badge-info'
                                : detSoli.estado == 2
                                ? 'badge-primary'
                                : detSoli.estado == 3
                                ? 'badge-warning'
                                : ''
                            "
                          >
                            <div v-if="detSoli.tipo_servicio == 1">
                              <div v-if="detSoli.tipo_vehiculo != null">
                                {{ index + 1 }} .
                                {{ detSoli.tipoServicio }} |
                                {{ detSoli.tipoVh }} -
                                {{ detSoli.tipoRemolque }} |
                                {{ detSoli.estadoDetSoli }}
                              </div>
                            </div>
                            <div v-else>
                              {{ index + 1 }} . {{ detSoli.tipoServicio }} -
                              {{ detSoli.nTipoEquipo }} -
                              {{ detSoli.estadoDetSoli }}
                            </div>
                          </span>
                        </p>
                      </td>
                      <td class="col-1 text-right">
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="
                              $store.getters.can(
                                'cs.asignacionesInterno.edit'
                              )
                            "
                            @click="showInterno(solicitud)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="solicitudesInterna.total">
                  Mostrando del <b>{{ solicitudesInterna.from }}</b> al
                  <b>{{ solicitudesInterna.to }}</b> de un total:
                  <b>{{ solicitudesInterna.total }}</b> Registros
                </div>
                <div class="float-left" v-else>
                  <span class="badge badge-danger">
                    No hay registros para mostrar
                  </span>
                </div>
                <pagination
                  :data="solicitudesInterna"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right m-0"
                ></pagination>
              </div>
            </div>
            <CsSolicitudesIntDetalle ref="csSolicitudesIntDetalle" />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import CsSolicitudesIntDetalle from "../../../funcionariosFrontera/CargaSeca/internas/CsSolicitudesIntDetalle";

export default {
  name: "CsAsignacionIndex",
  components: {
    Loading,
    pagination,
    CsSolicitudesIntDetalle,
  },

  data() {
    return {
      cargando: false,
      solicitudesInterna: {},
      filtros: {},
      listasForms: {
        tipos_solicitudes: [],
        estados: [],
      },
    };
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      this.filtros.accion = 2;
      axios
        .get("/api/cs/asignacionesSolicitudesInternas?page=" + page, {
          params: this.filtros,
        })
        .then(async (response) => {
          this.solicitudesInterna = response.data;
          this.cargando = false;
        });
    },

    resetFilter(){
      this.filtros = {};
      this.getIndex();
    },

    getTipoSolicitud() {
      axios.get("/api/lista/127").then((response) => {
        this.listasForms.tipos_solicitudes = response.data;
      });  
    },  

    getEstados() {
      axios.get("/api/lista/142").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    llenarModal(solicitud) {
      if (this.$store.getters.can("cs.asignacionesInterno.index")) {
        this.$refs.csSolicitudesIntDetalle.llenar_modal(solicitud);
      }
    },

    showInterno(solicitud) {
      return this.$router.push({
        name: "/Cs/AsignacionesInternosForm",
        params: { accion: "Editar", data_edit: solicitud },
      });
    },
  },

  beforeMount(){
    this.getTipoSolicitud();
    this.getEstados();
  },

  mounted() {
    this.getIndex();
  },
};
</script>
